@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,300,500,700");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.finn-ut-hvordan-button a {
  color: #fff;
  padding: 5px 15px;
}

.finn-ut-hvordan-button:hover a {
  background-color: #fff;
  border-color: #fefdfe;
  color: #a0a09f;
  transition: 0.5s all;
}

.parallax-block img {
  min-height: 205px;
  width: 100%;
  object-fit: cover;
}

.languageChangeDropdown .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5.5px 14px;
}

.languageChangeDropdown fieldset{
  border: none;
}
.languageChangeDropdown svg{
  color: #fff;
}

.footer-container {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.footer_link {
  display: inline-block;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin-bottom: 50px;
  border-color: rgba(160, 160, 159, 0.3);
}

.footer_a_link {
  color: #9bc4c4;
  transition: color 0.3s;
}

.footer_a_link:hover {
  color: #4a4c67;
  cursor: pointer;
}

.sign-up-text {
  background-color: #c0bbcf;
  border: 1px solid #9bc4c4;
  border-radius: 0px;
  color: #2e403a;
  font-size: 14px;
  line-height: 1.4em;
  width: 40%;
  padding: 7px 8px;
}

.active-link {
  color: red;
  font-weight: bold;
}

.footer_a_link.active {
  color: #2e403a;
}

.head_faq {
  color: #9bc4c4;
  margin-left: 20% !important;
  margin-right: 20% !important;
  text-align: left !important;
}

.faq-tabs {
  color: #2e403a;
}

.faq-tabs .Mui-selected {
  color: #2e403a !important;
}

.faq-tabs .MuiTabs-indicator {
  background-color: #fff !important;
}

.faq-accor {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none;
  border-top: 2px solid;
  border-color: rgba(142, 141, 144, 0.6125) !important;
  margin: 0px 15px;
  padding-top: 16px;
  margin-bottom: 20px;
  width: 73%;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cfcfcf;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b4b4b4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

.event-checklist{
  position: relative;
}
.event-checklist > label{
  width: 100%;
  min-height: 45px;
}
.event-checklist .check-icon{
  position: absolute;
  left: 10px;
  top: 60%;
  transform: translateY(-50%);
  color: #fff;
}

.error-text, .MuiFormHelperText-contained{
  color: #d32f2f !important;
  margin-left: 14px;
  font-size: 10px;
  margin-top: 4px;
}